export let valueTypes = [
    "DirectValue",
    "Percentage",
    "DurationInSeconds",
    "NumOfTapsPerSecond",
    "NumOfSlots",
];
export let ValueTypeNames = {
    "DirectValue": "DirectValue",
    "Percentage": "Percentage",
    "DurationInSeconds": "DurationInSeconds",
    "NumOfTapsPerSecond": "NumOfTapsPerSecond",
    "NumOfSlots": "NumOfSlots",
};
export let periodTypes = [
    "DurationInSeconds",
    "NumOfTaps",
    "NumOfGotHits",
];
export let PeriodTypeNames = {
    "DurationInSeconds": "DurationInSeconds",
    "NumOfTaps": "NumOfTaps",
    "NumOfGotHits": "NumOfGotHits",
};

export let ValueTypeShortcut = {
    'DirectValueExShort': {
        type: 'DirectValue',
        key: 'DirectValueExShort',
        value: 5,
        text: 'Direct value extra short',
        unit: '',
    },
    'DirectValueShort': {
        type: 'DirectValue',
        key: 'DirectValueShort',
        value: 10,
        text: 'Direct value short',
        unit: '',
    },
    'DirectValueMedium': {
        type: 'DirectValue',
        key: 'DirectValueMedium',
        value: 15,
        text: 'Direct value medium',
        unit: '',
    },
    'DirectValueLong': {
        type: 'DirectValue',
        key: 'DirectValueLong',
        value: 20,
        text: 'Direct value long',
        unit: '',
    },
    'DirectValueExLong': {
        type: 'DirectValue',
        key: 'DirectValueExLong',
        value: 40,
        text: 'Direct value extra long',
        unit: '',
    },
    //////////////
    'PercentageExShort': {
        type: 'Percentage',
        key: 'PercentageExShort',
        value: 5,
        text: 'Percentage extra short',
        unit: '%',
    },
    'PercentageShort': {
        type: 'Percentage',
        key: 'PercentageShort',
        value: 10,
        text: 'Percentage short',
        unit: '%',
    },
    'PercentageMedium': {
        type: 'Percentage',
        key: 'PercentageMedium',
        value: 15,
        text: 'Percentage medium',
        unit: '%',
    },
    'PercentageLong': {
        type: 'Percentage',
        key: 'PercentageLong',
        value: 20,
        text: 'Percentage long',
        unit: '%',
    },
    'PercentageExLong': {
        type: 'Percentage',
        key: 'PercentageExLong',
        value: 40,
        text: 'Percentage extra long',
        unit: '%',
    },
    'PercentageFull': {
        type: 'Percentage',
        key: 'PercentageFull',
        value: 100,
        text: 'Percentage 100%',
        unit: '%',
    },
    /////////////////////////
    'DurationExShort': {
        type: 'DurationInSeconds',
        key: 'DurationExShort',
        value: 5,
        text: 'Duration extra short',
        unit: 'seconds',
    },
    'DurationShort': {
        type: 'DurationInSeconds',
        key: 'DurationShort',
        value: 10,
        text: 'Duration short',
        unit: 'seconds',
    },
    'DurationMedium': {
        type: 'DurationInSeconds',
        key: 'DurationMedium',
        value: 15,
        text: 'Duration medium',
        unit: 'seconds',
    },
    'DurationLong': {
        type: 'DurationInSeconds',
        key: 'DurationLong',
        value: 20,
        text: 'Duration long',
        unit: 'seconds',
    },
    'DurationExLong': {
        type: 'DurationInSeconds',
        key: 'DurationExLong',
        value: 40,
        text: 'Duration extra long',
        unit: 'seconds',
    },
    /////////////////
    /////////////////////////
    'NumTapPerSecondExShort': {
        type: 'NumOfTapsPerSecond',
        key: 'NumTapPerSecondExShort',
        value: 5,
        text: 'Number of taps per second extra short',
        unit: 'taps',
    },
    'NumTapPerSecondShort': {
        type: 'NumOfTapsPerSecond',
        key: 'NumTapPerSecondShort',
        value: 10,
        text: 'Number of taps per second short',
        unit: 'taps',
    },
    /*'NumTapPerSecondMedium': {
        type: 'NumOfTapsPerSecond',
        key: 'NumTapPerSecondMedium',
        value: 15,
        text: 'Number of taps per second medium',
        unit: 'taps',
    },
    'NumTapPerSecondLong': {
        type: 'NumOfTapsPerSecond',
        key: 'NumTapPerSecondLong',
        value: 20,
        text: 'Number of taps per second long',
        unit: 'taps',
    },
    'NumTapPerSecondExLong': {
        type: 'NumOfTapsPerSecond',
        key: 'NumTapPerSecondExLong',
        value: 40,
        text: 'Number of taps per second extra long',
        unit: 'taps',
    },*/
    //////////

    'OneSlot': {
        type: 'NumOfSlots',
        key: 'OneSlot',
        value: 1,
        text: 'One slot',
        unit: 'slot',
    },
    'TwoSlots': {
        type: 'NumOfSlots',
        key: 'TwoSlots',
        value: 2,
        text: 'Two slots',
        unit: 'slots',
    },
};

export let PeriodTypeShortcut = {
    /*
    DurationOnce:{
        type: 'DurationInSeconds',
        key: 'DurationOnce',
        value: 1,
        text: 'Duration once',
        unit: 'second',
    },
    DurationLShort:{
        type: 'DurationInSeconds',
        key: 'DurationExShort',
        value: 2,
        text: 'Duration extra short',
        unit: 'seconds',
    },
    DurationExShort:{
        type: 'DurationInSeconds',
        key: 'DurationExShort',
        value: 3,
        text: 'Duration extra short',
        unit: 'seconds',
    },*/
    DurationShort:{
        type: 'DurationInSeconds',
        key: 'DurationShort',
        value: 4,
        text: 'Duration short',
        unit: 'seconds',
    },
    DurationShort2:{
        type: 'DurationInSeconds',
        key: 'DurationShort',
        value: 5,
        text: 'Duration short',
        unit: 'seconds',
    },
    DurationShort3:{
        type: 'DurationInSeconds',
        key: 'DurationShort',
        value: 6,
        text: 'Duration short',
        unit: 'seconds',
    },
    /*
    DurationMedium:{
        type: 'DurationInSeconds',
        key: 'DurationMedium',
        value: 20,
        text: 'Duration medium',
        unit: 'seconds',
    },
    DurationLong:{
        type: 'DurationInSeconds',
        key: 'DurationLong',
        value: 30,
        text: 'Duration long',
        unit: 'seconds',
    },
    DurationExLong:{
        type: 'DurationInSeconds',
        key: 'DurationExLong',
        value: 40,
        text: 'Duration extra long',
        unit: 'seconds',
    },*/

    NumTapExShort:{
        type: 'NumOfTaps',
        key: 'NumTapExShort',
        value: 1,
        text: 'Number of taps extra short',
        unit: 'taps',
    },
    NumTapMShort:{
        type: 'NumOfTaps',
        key: 'NumTapExShort',
        value: 3,
        text: 'Number of taps extra short',
        unit: 'taps',
    },
    NumTapSShort:{
        type: 'NumOfTaps',
        key: 'NumTapExShort',
        value: 5,
        text: 'Number of taps extra short',
        unit: 'taps',
    },
    /*NumTapShort:{
        type: 'NumOfTaps',
        key: 'NumTapShort',
        value: 6,
        text: 'Number of taps short',
        unit: 'taps',
    },
    NumTapMedium:{
        type: 'NumOfTaps',
        key: 'NumTapMedium',
        value: 30,
        text: 'Number of taps medium',
        unit: 'taps',
    },
    NumTapLong:{
        type: 'NumOfTaps',
        key: 'NumTapLong',
        value: 40,
        text: 'Number of taps long',
        unit: 'taps',
    },
    NumTapExLong:{
        type: 'NumOfTaps',
        key: 'NumTapExLong',
        value: 60,
        text: 'Number of taps extra long',
        unit: 'taps',
    },*/

    NumGotHitExShort:{
        type: 'NumOfGotHits',
        key: 'NumGotHitExShort',
        value: 1,
        text: 'Number of got hits extra short',
        unit: 'got-hits',
    },
    NumGotHitShort:{
        type: 'NumOfGotHits',
        key: 'NumGotHitShort',
        value: 2,
        text: 'Number of got hits short',
        unit: 'got-hits',
    },
    /*
    NumGotHitMedium:{
        type: 'NumOfGotHits',
        key: 'NumGotHitMedium',
        value: 3,
        text: 'Number of got hits medium',
        unit: 'got-hits',
    },
    NumGotHitLong:{
        type: 'NumOfGotHits',
        key: 'NumGotHitLong',
        value: 4,
        text: 'Number of got hits long',
        unit: 'got-hits',
    },
    NumGotHitExLong:{
        type: 'NumOfGotHits',
        key: 'NumGotHitLong',
        value: 5,
        text: 'Number of got hits extra long',
        unit: 'got-hits',
    },*/
};

let valueTypeSymbols = {
    "DirectValue" : "",
    "Percentage" : "%",
    "DurationInSeconds" : "seconds",
    "NumOfTapsPerSecond" : "taps per second",
    "NumOfSlots" : "slots",
    "Capacity" : "cap",
    "DMGToPercent": "(dmg/bossHp)"
};

let valueTypeKhmerSymbols = {
    "DirectValue" : "",
    "Percentage" : "ភាគរយ",
    "DurationInSeconds" : "វិនាទី",
    "NumOfTapsPerSecond" : "ចុចក្នុងមួយវិនាទី",
    "NumOfSlots" : "ប្រឡោះ",
    "Capacity" : "ថត",
    "DMGToPercent": "(កម្លាំង/ឈាមមេ)"
};

let periodTypeSymbols = {
    "NumOfTaps" : "taps",
    "DurationInSeconds" : "seconds",
    "NumOfGotHits" : "got hits",
};

let periodTypeKhmerSymbols = {
    "NumOfTaps" : "ចុច",
    "DurationInSeconds" : "វិនាទី",
    "NumOfGotHits" : "ដងគេវាយ",
};

export default {valueTypes, periodTypes, valueTypeSymbols, periodTypeSymbols, valueTypeKhmerSymbols, periodTypeKhmerSymbols};
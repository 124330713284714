<template>
    <v-card
        max-height="900"
    >

        <template>
            <v-row>
                <v-col class="mt-1 ml-3" cols="3">
                    <div class="font-weight-black">$G and Items info</div>
                    <v-row class="ml-0 mt-1">
                        $GAvailable: {{this.kpiGoldEnding.ToReadableAlphabetV2()}}
                    </v-row>
                    <v-row class="ml-0">
                        $GItem Spent: {{this.computeItemPurchase.ToReadableAlphabetV2()}}
                    </v-row>
                    <v-row class="ml-0 mb-2">
                        Bought items: {{itemPurchased.length}}
                    </v-row>
                </v-col>
                <v-col v-if="this.itemPurchased.length > 0" class="mt-1">
                    <div class="font-weight-black">Impact bonus bought item ({{impactBonus.length}})</div>
                    <v-virtual-scroll
                            item-height="70"
                            :height="computeMaxImpactHeight"
                            :items="impactBonus"
                            class="pl-2 mt-0 mb-0"
                    >
                        <template v-slot:default="{ item }">
                            <v-checkbox
                                    v-model="item.isApply"
                                    :disabled="!item.isCanApply"
                                    :indeterminate="!item.isCanApply"
                                    @click="onTickClick(item)"
                            >
                                <template v-slot:label>
                                    <div v-html="getFullImpactDescription(item)"></div>
                                    <v-col>
                                        <v-checkbox
                                                :disabled="!item.isCanSelectMode"
                                                :indeterminate="!item.isCanSelectMode"
                                                v-model="item.modes.Battle"
                                                label="Boss"
                                                @click.stop
                                                @click.prevent="onTickModeClick(item)">
                                        </v-checkbox>
                                    </v-col>
                                    <v-col>
                                        <v-checkbox
                                                :disabled="!item.isCanSelectMode"
                                                :indeterminate="!item.isCanSelectMode"
                                                v-model="item.modes.Normal"
                                                label="Normal"
                                                @click.stop
                                                @click.prevent="onTickModeClick(item)">
                                        </v-checkbox>
                                    </v-col>
                                </template>
                            </v-checkbox>
                        </template>
                    </v-virtual-scroll>
                </v-col>
            </v-row>
        </template>
        <template>
            <div class="font-weight-black mt-1 ml-3"> Marketplace items at stage {{kpiGameLevel}} ({{dataItems.length}})</div>
            <v-virtual-scroll
                    item-height="150"
                    :height="600"
                    :items="marketItemList"
                    class="ml-0 pt-4"
            >
                <template v-slot:default="{ item }">
                    <v-row dense no-gutters class="mt-4 ml-0">
                        <v-col
                                class="mx-8"
                        >
                            <MarketPlaceCard
                                    :pItemData="item.itemData"
                                    :pLevelCardData="item.levelData"
                                    :pIsPurchased="item.isPurchased"
                                    @onClickPurchase="onClickPurchase"
                            ></MarketPlaceCard>
                        </v-col>
                    </v-row>
                </template>
            </v-virtual-scroll>
        </template>
    </v-card>
</template>

<script>
    import MarketPlaceCard from './components/GroupTabMarketPlaceCard';
    import ItemApi from '@/item-editor/dao/ItemApi';
    import SiblingItemMultipleImpactUtils from "@/item-editor/Utils/SiblingItemMultipleImpactUtils";
    import SMPNum from "@/SMPNum";
    import ItemSimulatorUtils from './../../Utils/ItemSimulatorUtils';
    import SMPItemImpactBatchHelperUtils from './../../Utils/SMPItemImpactBatchHelperUtils';
    import UGSItemUtils from "@/item-editor/Utils/UGSItemUtils";
    import {GlobalEvents, EVENT_REFRESH_MARKET} from "@/item-editor/events/GlobalEvents";

export default {
    name: "GroupTabMarketPlace",
    props: ['payload'],
    components: {
        MarketPlaceCard,
    },
    data(){
        return {
            optimizedList: [],
            dataItems: [],
            gameLevel: 0,
            itemPurchased: [],
            impactBonus: [],
            itemApplySelected: [],
            itemSimulatorUtils: undefined,
            itemAvailableOnMarketSimulatorUtils: undefined,
            gamePlayDataService: null,
            marketItemList: [],
            isUseAllGoldForMarket: false,
        }
    },
    created(){
        this.initSiblingUtil();
        const {kpiUseAllGoldForMarket} = this.payload;
        this.isUseAllGoldForMarket = kpiUseAllGoldForMarket;
        GlobalEvents.$on(EVENT_REFRESH_MARKET, () => this.loadItems(this.gameLevel, this.isUseAllGoldForMarket));
        /*disable for avoid error because we need to use new rule*/
        //this.loadItems(this.kpiGameLevel);
    },
    computed: {
        kpiGameLevel() {
            const {kpiGameLevel} = this.payload;
            /*disable for avoid error because we need to use new rule*/
            if(this.gameLevel !== kpiGameLevel){
                this.loadItems(kpiGameLevel, this.isUseAllGoldForMarket);
            }
            return kpiGameLevel;
        },
        kpiUseAllGoldForMarket(){
            const {kpiUseAllGoldForMarket} = this.payload;
            if(this.isUseAllGoldForMarket !== kpiUseAllGoldForMarket){
                this.loadItems(this.gameLevel, kpiUseAllGoldForMarket);
            }
            return kpiUseAllGoldForMarket;
        },
        kpiGoldEnding(){
            if(this.kpiUseAllGoldForMarket){
                const {kpiCumulatedGoldWon} = this.payload;
                let gEnding = new SMPNum(0);
                let gPurchase = this.computeItemPurchase;
                if(SMPNum.greaterThan(kpiCumulatedGoldWon, gPurchase)){
                    gEnding = SMPNum.minus(kpiCumulatedGoldWon, gPurchase);
                }
                return gEnding;
            } else {
                const {kpiGoldEnding} = this.payload;
                let gEnding = new SMPNum(0);
                let gPurchase = this.computeItemPurchase;
                if(SMPNum.greaterThan(kpiGoldEnding, gPurchase)){
                    gEnding = SMPNum.minus(kpiGoldEnding, gPurchase);
                }
                return gEnding;
            }
        },
        computeItems(){
            let list = [];
            let gameLevel = this.kpiGameLevel;
            this.dataItems.forEach(item =>{
                if(item.gameLevel.levelStart<= gameLevel && item.gameLevel.levelEnd >= gameLevel){
                    list.push({
                        itemData: item,
                        levelData: this.getLevelCardData(item),
                        isPurchased: this.getIsItemPurchased(item)
                    });
                }
            });
            return list;
        },
        computeItemPurchase(){
            let cost = new SMPNum(0);
            this.itemPurchased.forEach(item =>{
                cost = SMPNum.plus(cost, item.levelData.costInGold);
            });
            return cost;
        },
        computeMaxImpactHeight(){
            let count = this.impactBonus.length;
            if(count>3){
                count = 3;
            }
            return (count * 70) + 20;
        }
    },
    methods: {
        loadItems(level, isUseAllGoldForMarket) {
            //console.log('load item level '+level);
            this.gameLevel = level;
            this.itemPurchased = [];
            this.isUseAllGoldForMarket = isUseAllGoldForMarket;
            ItemApi.getItemsAtGameLevel(this.gameLevel, this.$store.state.ugsSetting.environmentId)
                .then(data => {
                    this.dataItems = data;
                    this.ApplyBatchImpactDirectValue();
                    this.refreshMarketItems();
                    this.generateMarketItemList();
                });
        },
        generateMarketItemList(){
            this.marketItemList = [];
            this.itemAvailableOnMarketSimulatorUtils.clearImpactOfAnyItem();
            setTimeout(() => {
               this.marketItemList = this.optimizedList;
                this.refreshAllMarketImpactWillBonus();
            }, 300);
        },
        ApplyBatchImpactDirectValue() {
            this.dataItems.forEach(data => {
                //Apply batch impact direct value
                data.behaviours.forEach(behaviour => {
                    this.itemImpactBatchHelperUtils.ApplyBatchImpactDirectValue(behaviour, data.gameLevel.levelStart);
                });
            });
        },
        initSiblingUtil(){
            this.gamePlayDataService = this.$store.state.gamePlayDataService;
            this.siblingUtils = new SiblingItemMultipleImpactUtils(this.gamePlayDataService);
            this.itemImpactBatchHelperUtils = new SMPItemImpactBatchHelperUtils(this.gamePlayDataService);
            this.itemSimulatorUtils = new ItemSimulatorUtils(this.$store.state.itemImpactBonus, this.gamePlayDataService);
            this.itemAvailableOnMarketSimulatorUtils = new ItemSimulatorUtils(this.$store.state.marketItemWillBonus, this.gamePlayDataService);
        },
        refreshMarketItems(){
            this.optimizedList = this.computeItems;
            this.refreshImpactBonus();
        },
        refreshImpactBonus(){
            this.itemApplySelected = [];
            this.impactBonus = [];
            this.itemSimulatorUtils.clearImpactOfAnyItem();
            this.itemPurchased.forEach(item => {
                let exitBonus = {
                    itemData: item.itemData,
                    title: item.levelData.bonusDesc,
                    bonus: this.getLevelDataBonusValue(item.levelData),
                    isCanApply: this.itemSimulatorUtils.getItemCanApply(item.itemData),
                    isApply: false,
                    joinBonus: [],
                    behaviorDesc: item.levelData.behaviorDesc,
                    modes:item.levelData.modes,
                    isCanSelectMode: this.itemSimulatorUtils.getItemCanApplyModeOption(item.itemData),
                };
                this.impactBonus.push(exitBonus);
                if(item.levelData.joinLevelDatas){
                    item.levelData.joinLevelDatas.forEach(levelData =>{
                        exitBonus.joinBonus.push({
                            title: levelData.bonusDesc,
                            bonus: this.getLevelDataBonusValue(levelData),
                            uid: "joinLvD_"+exitBonus.joinBonus.length,
                            isCanApply: levelData.isCanApply
                        })
                    });
                }
            });
        },

        refreshAllMarketImpactWillBonus(){
            let impactWillBonus = [];
            this.marketItemList.forEach(item => {
                let exitBonus = {
                    itemData: item.itemData,
                    title: item.levelData.bonusDesc,
                    bonus: this.getLevelDataBonusValue(item.levelData),
                    isCanApply: true,//this.itemSimulatorUtils.getItemCanApply(item.itemData),
                    isApply: false,
                    joinBonus: [],
                    behaviorDesc: item.levelData.behaviorDesc,
                    modes:item.levelData.modes,
                    isCanSelectMode: this.itemSimulatorUtils.getItemCanApplyModeOption(item.itemData),
                };
                impactWillBonus.push(exitBonus);
                if(item.levelData.joinLevelDatas){
                    item.levelData.joinLevelDatas.forEach(levelData =>{
                        exitBonus.joinBonus.push({
                            title: levelData.bonusDesc,
                            bonus: this.getLevelDataBonusValue(levelData),
                            uid: "joinLvD_"+exitBonus.joinBonus.length,
                            isCanApply: true,//levelData.isCanApply
                        })
                    });
                }
            });

            const {itemRequirePayload} = this.payload;
            this.itemAvailableOnMarketSimulatorUtils.applyImpactOfItemData(impactWillBonus, this.gameLevel, false, itemRequirePayload);
        },

        getLevelDataBonusValue(levelData){
            if(levelData.bonus === 'n/a'){
                if(levelData.bonusType === "Percentage"){
                    return levelData.percentage + ' (%)';
                } else{
                    return '';
                }
            }
            return levelData.bonus;
        },
        getIsItemPurchased(itemData){
            return this.itemPurchased.length > 0 && this.itemPurchased.find(itp => itp.itemData.id === itemData.id);
        },
        getLevelCardData(item){
            let lv = item.gameLevel.levelStart;
            let beh = item.behaviours[0];
            //let refBonus = this.siblingUtils.getBonusTextOnLevelOfImpact(item, beh, lv);
            let valueType = beh.gamePlayImpact.valueType;

            //define cost
            let levelConfig = item.levelConfig;
            let goldDrop = this.gamePlayDataService.getCoinDrop(levelConfig.kpiLevel).round();
            let smpGem = UGSItemUtils.getSmpGemUnit(item.groupConfig);
            let costInGold = SMPNum.multSmpNum(goldDrop, new SMPNum(smpGem));

            //debug low price
            //costInGold = SMPNum.divSmpNum(costInGold, new SMPNum(smpGem));

            //add color for price
            let costColor = '#B1B1B1';//gray or brown
            if(costInGold && SMPNum.greaterThan(this.kpiGoldEnding, costInGold)){
                costColor = '#F020D8';
            }

            let bonus = beh.gamePlayImpact.value;

            let lifeDesc = item.lifeTime.bean.text;
            if(this.siblingUtils.isDefineByLifeTimeLimit(beh.type)){
                if(item.lifeTime.type === "DurationInSeconds"){
                    let limit = this.siblingUtils.getImpactReduceDurationToEndLevel(item.lifeTime.limit, lv, item.gameLevel.levelEnd, this.gameLevel);
                    lifeDesc = limit.toFixed(2)+' seconds';
                }
            }

            let levelData = {
                level: lv,
                bonus: bonus,
                bonusType: valueType,
                // base: refBonus.base,
                // percentage: refBonus.percentage === 'n/a' ? beh.gamePlayImpact.value : refBonus.percentage,
                // baseType: refBonus.baseType,
                costInGold: costInGold,
                costColor: costColor,
                bonusDesc: beh.behaviourBean.des.title,
                lifeDesc: lifeDesc,
                behaviorDesc: [{
                    isCanApply: this.itemSimulatorUtils.getBehaviourCanApply(beh),
                    desc: this.renderDetailBean(item, beh)
                }],

                modes:{
                    Battle: item.modes.includes('Battle'),
                    Normal: item.modes.includes('Normal')
                }
            };

            if(item.behaviours.length > 1){
                levelData.joinLevelDatas = [];
                for(let index = 1;index<item.behaviours.length;index++){
                    let beh = item.behaviours[index];
                    // let refBonus = this.siblingUtils.getBonusTextOnLevelOfImpact(item, beh, lv);
                    let valueType = beh.gamePlayImpact.valueType;
                    levelData.joinLevelDatas.push({
                        bonus: beh.gamePlayImpact.value,
                        bonusType: valueType,
                        // base: refBonus.base,
                        // percentage: refBonus.percentage === 'n/a' ? beh.gamePlayImpact.value : refBonus.percentage,
                        // baseType: refBonus.baseType,
                        costInGold: costInGold,
                        bonusDesc: beh.behaviourBean.des.title,
                    });
                    levelData.behaviorDesc.push({
                        isCanApply: this.itemSimulatorUtils.getBehaviourCanApply(beh),
                        desc: this.renderDetailBean(item, beh)
                    });
                }
            }
            return levelData;
        },
        renderDetailBean(item, behaviour){
            let detail = "" + behaviour.behaviourBean.des.details;
            if(behaviour.type === "inc_powerup_capacity"){
                let skillType = behaviour.gamePlayImpact.skillType;
                if(skillType !== null) {
                    if (detail.includes("Power up")) {
                        detail = detail.replace("Power up", skillType);
                    } else {
                        detail += " " + behaviour.gamePlayImpact.skillType;
                    }
                } else if (detail.includes("Power up")) {
                    detail = detail.replace("Power up", "all power-up");
                }
            }

            detail += " "+ this.renderGameplayImpact(item, behaviour);

            let isCanApply = this.itemSimulatorUtils.getBehaviourCanApply(behaviour);
            return this.getDescHTMLApplied(detail, isCanApply);
        },
        renderGameplayImpact: function (item, behaviour) {
            if (behaviour.gamePlayImpact.value < 0 || !behaviour.gamePlayImpact.valueType) {
                return '';
            }
            //let des = "";
            let bonus = this.itemImpactBatchHelperUtils.getTextItemImpactAmount(behaviour.gamePlayImpact, behaviour.type,item.gameLevel, this.gameLevel);//behaviour.gamePlayImpact.value;
            return bonus;
            // let bean = behaviour.gamePlayImpact.bean;
            // if(bean !== null && bean.valueType !== null && bean.valueType.value === "DirectDisplayValue"){
            //     des += bean.valueType.text;
            // } else if(behaviour.gamePlayImpact.valueType === "DirectValue"){
            //     des = SMPNum.fromPowerString(bonus).ToReadableAlphabetV2();
            // } else if(behaviour.gamePlayImpact.valueType === 'Percentage'){
            //     des = bonus.toString();
            // } else {
            //     des = new SMPNum(bonus).ToReadableAlphabetV2();
            // }
            // des += " "
            //     + GamePlayImpactType.valueTypeSymbols[behaviour.gamePlayImpact.valueType];
            //
            // if (behaviour.gamePlayImpact.periodValue > 0) {
            //     des += ' every ' + behaviour.gamePlayImpact.periodValue
            //         + " "
            //         + GamePlayImpactType.periodTypeSymbols[behaviour.gamePlayImpact.periodType];
            // }

            //return des;
        },

        onClickPurchase(itemData, levelData){
            if(SMPNum.greaterThan(this.kpiGoldEnding, levelData.costInGold)){
                if(!this.getIsItemPurchased(itemData)){
                    this.itemPurchased.push({
                        itemData: itemData,
                        levelData: levelData,
                    });
                    this.refreshMarketItems();
                    this.generateMarketItemList();
                }
            }
        },
        onTickClick(item){
            if(!this.itemApplySelected.includes(item)){
                //avoid duplicate behaviour type
                if(this.itemApplySelected.length > 0) {
                    let dupSelect = [];
                    this.itemApplySelected.forEach(its => {
                        item.itemData.behaviours.forEach(beh =>{
                            its.itemData.behaviours.forEach(itBeh => {
                                if(itBeh.type === beh.type){
                                    let indexDup = this.itemApplySelected.indexOf(its);
                                    if(!dupSelect.includes(indexDup)){
                                        its.isApply = false;
                                        dupSelect.push(indexDup);
                                    }
                                }
                            });
                        });
                    });
                    if(dupSelect.length > 0){
                        dupSelect.forEach(dup => {
                            this.itemApplySelected.splice(dup, 1);
                        });
                    }
                }
                this.itemApplySelected.push(item);
            }
            this.itemSimulatorUtils.applyImpactOfItemData(this.itemApplySelected, this.gameLevel, true);
        },
        onTickModeClick(item){
            if(this.itemApplySelected && this.itemApplySelected.includes(item)){
                this.itemSimulatorUtils.applyImpactOfItemData(this.itemApplySelected, this.gameLevel, true);
            }
        },
        getFullImpactDescription(impactBonus){
            let desc = this.getDescHTMLApplied(impactBonus.title+" "+ impactBonus.behaviorDesc[0].desc, impactBonus.behaviorDesc[0].isCanApply);
            if(impactBonus.behaviorDesc.length> 0){
                for(let index=0;index<impactBonus.joinBonus.length; index++){
                    let joinBonus = impactBonus.joinBonus[index];
                    desc += " | "+ this.getDescHTMLApplied(joinBonus.title + " " + impactBonus.behaviorDesc[index+1].desc, impactBonus.behaviorDesc[index+1].isCanApply);
                }
            }
            return desc;
        },
        getDescHTMLApplied(desc, isApply){
            return '<font color='+this.getColorByApply(isApply)+'>'+desc+'</font>';
        },
        getColorByApply(isApply){
            if(isApply){
                return "#008B8B";
            } else {
                return "#DCDCDC";
            }
        }
    },
}
</script>

<style scoped>

</style>